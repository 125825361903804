<template>
  <component :is="currentPopup" v-if="showPopup" v-bind="currrentPopupProps" @close="handleClosePopup"></component>
  <HeartGround class="background"/>
  <div class="home">
    <img alt="logo" src="../assets/hx.png">
    <HxClock/>
    <HxSearch @popup="handleShowPopup" />
  </div>
  <div class="footer">
    <p>“我会出手” copyright©️何德铭 2023</p>
    <a href="https://beian.miit.gov.cn/">闽ICP备2020019489号-5</a>
  </div>
  <!-- 这是动态弹窗 -->
</template>

<script>
import HxClock from '@/components/HxClock.vue'
import HeartGround from '@/components/HeartGround.vue'
import HxSearch from '@/components/HxSearch.vue'
import InformationPopup from '@/components/InformationPopup.vue'

export default {
  name: 'HomeView',
  components: {
    HxClock,
    HeartGround,
    HxSearch,
    InformationPopup
  },
  data () {
    return {
      currentPopup: null,
      currrentPopupProps: {},
      showPopup: false
    }
  },
  methods: {
    handleShowPopup (component, props) {
      this.currentPopup = component
      this.currrentPopupProps = props
      this.showPopup = true
    },
    handleClosePopup () {
      this.currentPopup = null
      this.showPopup = false
    }
  },
}
</script>

<style>
a{
  color: gray;
}
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: gray;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
