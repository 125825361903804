<template>
    <div class="search-box">
        <a>What do you want to do</a>
        <input class="search-input" type="text" placeholder="" v-model="searchQuery" @input="handleInput" @keydown.enter="handleKeyDown" />
        <div class="search-result">
          <div class="result-item" v-for="command in filteredCommands" >
            <a :key="command.command">{{ command.command }}</a>
            <a class="description">{{ command.description }}</a>
          </div>
        </div>
    </div>
</template>

<script>
import commands from '@/assets/command.json'

export default {
  data () {
    return {
      commands: commands,
      searchQuery: ''
    }
  },
  components: {

  },
  computed: {
    filteredCommands() {
      const query = this.searchQuery.toLowerCase().trim();
      if (query === '') {
        return [];
      }
      return this.commands.filter((command) => {
        return command.command.toLowerCase().includes(query);
      });
    }
  },
  methods: {
    handleInput (event) {
      this.searchQuery = event.target.value
    },
    handleKeyDown(event) {
      if (event.keyCode === 13) {
        this.handleSearch();
      }
    },
    handleSearch () {
      // 执行搜索逻辑
      console.log(`搜索：${this.searchQuery}`)
      if (this.searchQuery === 'help') {
        this.$emit('popup', "InformationPopup", {
          message: '本站是一个命令行式的工具站，目前开放的命令有'.concat(this.commands.map((command) => command.command  + command.description).join(' | '))
        })
      }
      if (this.searchQuery === 'hxp1') {
        window.open('http://p2.imagic.ltd/')
      }
    }
  }
}
</script>

<style scoped>
.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-input {
  width: 50vw;
  line-height: 25px;
  font-size: 20px;
  color: gray;
  border: none;
  outline: none;
  border-radius: 100px;
  padding: 5px;
  caret-color:transparent;
  box-shadow: 0 0 5px #ddd;
  background-color: transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.search-result {
  display: flex;
  flex-direction: column;
  width: 50vw;
  border-radius: 10px;
  box-shadow: 0 0 5px #ddd;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  .result-item {
    padding: 5px;
    text-decoration: none;
    font-weight: bold;
    color: gray;

    .description {
      font-weight: normal;
    }
  }
}
</style>
