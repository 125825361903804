<template>
  <div class="bg" @click="handleClosePopup">
    <div class="information-box">
        <a>{{ message }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    message: String
  },
  methods: {
    handleClosePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.bg {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}
.information-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 30%;
  backdrop-filter: blur(90px);
}

</style>
